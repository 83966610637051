@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

.navigation {
  font-family: "Poppins", sans-serif;
  position: fixed;
  width: 60px;
  margin-left: 20px;
  height: 600px;
  margin-top: 50px;
  background: #ffffff;
  overflow: hidden;
  transition: 0.5s;
  border-radius: 10px;
  box-shadow: 0px 0px 18px 20px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(40.5px);
  z-index: 9999;
}

.navigation:hover,
.navigation.active {
  width: 220px;
}

.navigation ul {
  position: absolute;
  top: 0;
  left: -40px;
  width: 100%;
}

.navigation ul li {
  position: relative;
  width: 100%;
  list-style: none;
  transition: 1s;
}

.navigation ul li a {
  position: relative;
  display: block;
  width: 100%;
  display: flex;
  text-decoration: none;
  color: #000000;
}
.navigation ul li a:hover {
  color: #588380;
}
.navigation ul li a .icon {
  position: relative;
  display: block;
  min-width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
}

.navigation ul li a .icon .fa {
  font-size: 24px;
}
.navigation ul li a {
  color: rgba(149, 166, 217, 1);
}
.navigation ul li a .title {
  position: relative;
  display: block;
  padding: 0 10px;
  height: 60px;
  line-height: 60px;
  text-align: start;
  white-space: nowrap;
}

.toggle {
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 60px;
  cursor: pointer;
  transition: 0.7s;
}

.toggle.active {
  background: #ff7700;
}

.toggle::before {
  content: "\f0c9";
  font-family: fontAwesome;
  position: absolute;
  width: 100%;
  height: 100%;
  line-height: 60px;
  text-align: center;
  font-size: 24px;
  color: #fff;
}

.toggle.active::before {
  content: "\f00d";
}

@media (max-width: 767px) {
  .navigation {
    left: -60px;
  }
  .navigation.active {
    left: 0px;
    width: 100%;
  }
}
@media screen and (max-width: 820px) {
  .navigation {
    display: none;
  }
}
.active-link-sidenav span {
  color: #588380;
}

.navigation .sidebar-split {
  padding-bottom: 160px;
}
