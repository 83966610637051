.email-verified-page {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10rem;
  /* height: 100vh; */
  /* background-color: #f3f3f3; */
  margin-left: 5rem;
  margin-right: 5rem;
}

.content {
  text-align: center;
  background-color: #f6f5f5;
  padding: 6px;
  margin-top: -80px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 482px;
}

.Email {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333333;
}
.Email-text {
  font-size: 2rem;
}
.Email-content {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #555555;
}

.email_button {
  width: 320px;
  padding: 10px;
  background-color: #588380;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
}
#login-button {
  margin-bottom: 2.5rem !important;
}
.email_button:hover {
  background-color: #94b6b5;
}
