@font-face {
  font-family: "NATS Regular";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/NATS-Regular.otf");
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

.main {
  margin-bottom: 2rem;
  padding: 50px;
  border-radius: 29px;
  background: #f9f8f8;
  box-shadow: 7px 5px 81px 7px rgba(0, 0, 0, 0.25);
  width: 800px;
  margin: 0 auto;
  padding-bottom: 50px;
  /* border: 2px solid red; */
}
.container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 10px;
  /* border : 2px solid red; */
  background: #ffffff;
  box-shadow: 0px 0px 12px 5px rgba(0, 0, 0, 0.25);
  width: 800px;
  margin-top: 0.8rem !important;
  margin: 0 auto;
  margin-bottom: 7px;
  font-family: "Poppins", sans-serif;
  /* margin-bottom: 50px; */
}

.sec-1 {
  padding-right: 60px;
  padding-left: 60px;
  /* border-right: 2px solid #c5baba; */
  /* border : 2px solid rgb(0, 255, 128) */
}

.heading {
  font-size: 32px;
  font-weight: bold;
  /* margin-bottom: 20px; */
  padding-left: 100px;
  font-family: "NATS Regular";
  letter-spacing: 2px;
  color: #588380;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.team-info {
  display: flex;
  flex-direction: column;
  align-items: center;

  /* border : 2px solid red */
  margin-top: 2rem;
  padding: 1rem;
}

.team-riders-container,
.team-riders-container-2 {
  /* margin-top: 50px; */
  width: 300px;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* gap: 4rem; */
  justify-content: space-around !important;
  padding-right: 25px;
  padding-left: 25px;
  background: #c4dada;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}
.temm {
  display: flex;
  justify-content: center;
}
.team-riders-container-2 {
  padding-top: 10px;
  padding-right: 25px;
  padding-left: 25px;
  border-radius: 5px;
  background: #c4dada;
  font-family: "NATS Regular";
  font-size: 25px;
  letter-spacing: 2px;
  /* padding-bottom: 10px; */
}
.teams {
  /* border : 2px solid red */
}
.team-riders {
  font-size: 20px;
  font-weight: bold;
  margin-right: 10px;
  overflow: hidden;
  margin-top: 1.7rem;
}

.team-code {
  justify-content: space-between;
  width: 300px;
  display: flex;
}

.delete-button {
  color: red;
  cursor: pointer;
}
.team-admin {
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 4px;
}
.description-team-code {
  width: 300px;
  display: flex;
  flex-direction: column;
}

.description {
  width: 320px;
  font-family: "Poppins", sans-serif;
  margin-right: 10px;
  letter-spacing: 1px;
  font-size: 15px;
  font-weight: 500;
}

.team-code {
  font-family: "Poppins", sans-serif;
  margin-right: 10px;
  font-weight: 600;
}
.copy {
  display: flex;
  background-color: #e6e6e6;
  padding-left: 25px;
  justify-content: space-between;
  border-radius: 50px;
  width: 120px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-right: 1rem;
}
.copy-iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}
.hr {
  display: none;
}
.idea-sub {
  overflow: hidden !important;
}
.copy-button {
  padding: 0;
  background-color: transparent;
  border: 0px;
  margin-right: 25px;
}
.team-members-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  margin-bottom: 20px;
  border: 2px soid rgb(67, 87, 235) !important;
}

.team-member {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 16px;
  overflow: hidden !important;
  /* border : 2px solid red */
}

.team-member-name {
  font-family: "NATS Regular";
  letter-spacing: 1px;
  font-weight: bold;
  margin-right: 10px;
  font-size: large;
  margin-top: 0.1rem;
}
.sec-2 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  padding: 2rem;
  align-items: center;
  align-self: center;
  /* border : 2px solid red */
  padding: 3.2rem;
  /* gap: 11rem;s */
  overflow: hidden !important;
}
.h22 {
  /* margin-top: 3rem; */
  overflow: hidden !important;
  font-size: 1.2rem;
  font-weight: 600;
}
.countdown {
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: center;
  border: #588380 4px solid;
}

.countdown-timer {
  font-weight: bold;
  /* color: #333; */
  /* transform: scale(1.2,1.2); */
  font-size: 2rem;
}
.demi-point {
  cursor: not-allowed !important;
}
.point {
  cursor: pointer !important;
}
.countdown-text {
  font-weight: bold;
  color: #333;
  /* color: rgba(20,20,20,0.2); */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
}

.sec-2 h4 {
  font-family: "NATS Regular";
  letter-spacing: 2px;
  text-align: center;
  font-size: 20px;
}
.idea {
  width: 100%;
  text-align: center;
  font-family: "NATS Regular";
  display: flex;
  flex-direction: column;
}
.idea h3 {
  letter-spacing: 1px;
  font-size: 25px;
  margin-bottom: -20px;
}
.idea p {
  text-align: center;

  background-color: rgba(102, 52, 196, 0.29);
  color: black;
  font-weight: bold;
  letter-spacing: 1px;
  text-decoration: none;
  border-radius: 10px;
  margin-bottom: -20px;
  font-size: 18px;
  padding-top: 10px;
}
.idea button {
  text-align: center;
  background-color: #545257;
  border-radius: 10px;
  border: 0px;
  color: #fff;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 10px 14px;
  /* cursor: not-allowed !important; */
  /* -moz-margin-top: 10rem; Use -moz-margin-top for Firefox */
}
@-moz-document url-prefix() {
  .idea button {
    /* background-color: #e6e6e6; */
    margin-top: 3rem;
    /* cursor: not-allowed !important; */
    /* Apply the desired margin for Firefox */
  }
  .sec-2 {
    padding: 5.2rem;
  }
}
.team-code i {
  cursor: pointer;
  margin-left: -70px;
}
.team-code input:focus {
  outline: none;
  border: transparent;
}

@media screen and (max-width: 1350px) {
  .main {
    margin-top: 3rem;
  }
}
@media screen and (max-width: 380px) {
  .container {
    padding: 1rem;
  }
}
@media screen and (max-width: 520px) {
  .main {
    margin-top: 5rem !important;
  }
}
@media screen and (max-width: 820px) {
  .main {
    margin-top: 5rem !important;
  }
}
@media screen and (max-width: 1000px) {
  .final-box {
    padding-bottom: 2rem !important;
  }
  .container {
    flex-direction: column;
    width: 400px;
    margin: 0 auto;
    /* height: 1000px; */
  }
  .hr {
    display: block;
  }
  .main {
    padding-bottom: 2rem !important;
    width: 400px;
  }
  .heading {
    padding-left: 0px;
    text-align: center;
  }
  .sec-1 {
    padding-right: 40px;
    /* border-bottom: 2px solid #c5baba; */
  }
  .team-code {
    width: 250px;
  }
}
@media screen and (max-width: 500px) {
  * {
    /* overflow-x: hidden; */
  }
  .sec-1 {
    padding-left: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-right: 0px !important;
  }
  .main {
    width: 320px;
  }
  .team-riders-container {
    /* flex-direction: space; */
    /* display: flex; */
    justify-content: space-between !important;
  }
  .container {
    flex-direction: column;
    background: none;
    box-shadow: none;
    /* background-color: #588380; */
    margin-left: -2.5rem !important;
    margin-right: 1rem;
    /* width: 320px; */
  }
  .team-riders-container-2 {
    /* margin-left: 15px; */
  }
  .team-riders {
    font-size: 15px;
  }
  .sec-1 {
    /* padding-right: 3rem; */
  }
  .team-riders-container,
  .team-riders-container-2 {
    /* width: 277px; */
    gap: 1rem;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .team-riders-container-2 {
    text-align: center;
    margin-right: 0px;
  }
  .team-riders {
    margin-right: 0px;
  }
  .team-info {
    margin-top: 0px;
  }
}

@media screen and (max-width: 420px) {
  .team-members-container {
    /* display:; */
    grid-template-columns: none;
  }
  .team-riders-container-2 {
    margin-right: 19px;
  }
  .delete-button {
    margin-top: 0;
  }
  .container {
    margin-left: 1rem !important;
    /* background-color: #588380; */
  }
  .team-info {
    padding: 20px;
    align-items: flex-start !important;
  }
  .hr {
    width: 243px;
  }
  .copy {
    margin-right: 1rem !important;
    width: 100px;
  }
  .main {
    width: 70%;
  }
  /* .container{
    width: 95%;
  } */
  .sec-1 {
    width: 290px;
  }
  .heading {
    font-size: 28px;
  }
  .team-riders {
    font-size: 11px;
    margin: 0;
  }
  .team-riders-container {
    height: 50px;
  }
  .delete-button {
    font-size: 12px;
  }
  .description {
    margin: 0 auto;
    font-size: 12px;
    width: 200px;
    margin-bottom: 10px;
    padding: 1rem;
    padding-left: 0rem;
  }
  .description-team-code {
    width: 200px;
  }
  /* .team-code{
    padding: 0;
    margin: 0;
  } */
  .team-code {
    margin-left: 1rem !important;
  }
  .team-riders-container {
    width: 200px;
  }
  .copy input {
    width: 50px;
  }
  .copy-button {
    margin-right: 0px;
  }
  .container {
    margin: 0px !important;
    padding: 0px !important;
    width: 300px !important;
  }
  .delete-button {
    margin: 0px !important;
  }
  .hr {
    width: 296px;
  }
}
@media screen and (max-width: 385px) {
  .container {
    margin-left: -1rem !important;
  }
}
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
