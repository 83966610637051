.tags-input {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    min-height: 33px;
    width: 94.5%;
    padding: 0 8px;
    /* border: 1px solid rgb(28, 138, 247); */
    background-color:rgba(165, 164, 177,0.24) ;
    border-radius: 6px;
    margin-left:-9px;
    margin-right: 13rem;
    /* border: 2px solid red; */

  }

  .tag-list {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 8px 0 0 0;
  }
  
  .tag {
    display: flex;
    align-items: center;
    margin-right: 8px;
    margin-bottom: 8px;
    background-color:rgba(8, 8, 8, 0.678) ;
    padding: 4px 8px;
    border-radius: 4px;
    /* height: 20px; */
    margin-top: .5rem;
  }
  
  .tag-title {
    margin-right: 8px;
  }
  
  .tag-close-icon {
    cursor: pointer;
  }
  
  .tag-input {
    flex: 1;
    border: none;
    height: 32px;
    font-size: 14px;
    padding: 0 8px;
  }
  
  .tag-input:focus {
    outline: none;
    border-color: #0052cc;
  }
  