.stats-page {
  width: 1300px;
  border-radius: 30px;
  background-color: #fff;
  margin: 0 auto;
  margin-top: 50px;
}
.number-stats {
  margin-top: 25px;
  margin-left: 50px;
  margin-bottom: 50px;
  display: flex;
  width: 1200px;
  justify-content: space-evenly;
}
.chart-box {
  width: 250px;
  height: 230px;
  background-color: #fbfbfb;
  box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  margin-top: 20px;
}
.chart-box h1 {
  text-align: center;
  color: #588380;
  margin: 0 auto;
  font-family: "Poppins", sans-serif;
  font-size: 55px;
  padding-top: 30px;
}
.chart-box h3 {
  color: #588380;
  font-family: "Poppins", sans-serif;
  text-align: left;
  font-size: 15px;
  padding-left: 20px;
}

.chart {
  width: 250px;
  height: 150px;
  padding-bottom: 52px;
}
@media screen and (max-width: 1470px) {
  .stats-page {
    width: 1000px;
  }
  .number-stats {
    width: 900px;
  }
  .chart-box {
    width: 200px;
    height: 250px;
  }
  .chart {
    width: 200px;
    height: 150px;
  }
}
@media screen and (max-width: 1050px) {
  .number-stats {
    flex-wrap: wrap;
  }
  .stats-page {
    width: 700px;
  }
  .number-stats {
    width: 600px;
  }
}
@media screen and (max-width: 720px) {
  .number-stats {
    flex-wrap: wrap;
  }
  .stats-page {
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }
  .number-stats {
    width: 540px;
  }
}
.chart-box {
  width: 250px;
  height: 250px;
}
.chart {
  width: 250px;
  height: 150px;
}
@media screen and (max-width: 600px) {
  .number-stats {
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin: 0 auto;
  }
  .stats-page {
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.user-stats {
  height: 500px;
  display: flex;
  justify-content: space-evenly;
}
.line-chart {
  width: 500px;
  background-color: #fff;
  box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  margin-bottom: 20px;
}
.line-chart h1 {
  color: #588380;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  text-align: center;
}
.charts {
  width: 500px;
  background-color: #fff;
  box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  margin-bottom: 20px;
}
.charts h1 {
  color: #588380;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  text-align: center;
}
.user-chart {
  width: 500px;
  height: 400px;
  overflow-x: auto;
}
#map {
  margin: 0 auto;
  margin-bottom: 25px;
}
.map-stats {
  margin: 0 auto;
  border-radius: 9px;
  text-align: center;
  color: #588380;
  font-family: "Poppins", sans-serif;
  width: 1100px;
  background-color: #fbfbfb;
  box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.25);
}
.map-stats h1 {
  padding-top: 10px;
}
.member-details {
  width: 500px;
  overflow-x: auto;
}
.member-table {
  width: 2500px;
  overflow: auto;
  font-family: "Poppins", sans-serif;
  color: #2b3674;
  border-collapse: collapse;
  font-size: 14px;
}
.previous-btn,
.next-btn {
  background-color: transparent;
  border: 0px;
  color: #588380;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  margin-bottom: 20px;
  cursor: pointer;
}
.previous-btn:hover,
.next-btn:hover {
  color: #432b86;
}
.map-stats {
  margin-bottom: 50px;
}
.previous-btn:disabled,
.next-btn:disabled {
  color: #633ec96f;
  cursor: default;
}
@media screen and (max-width: 1450px) {
  .user-stats {
    flex-direction: column;
    height: 1000px;
    justify-content: center;
    align-items: center;
  }
  .map-stats {
    width: 900px;
  }
  #map {
    width: 900px;
  }
}
.map-container {
  height: 500px;
  width: 100%;
}
@media screen and (max-width: 1050px) {
  .map-stats {
    width: 600px;
  }
}
@media screen and (max-width: 650px) {
  .map-stats {
    width: 100%;
  }
  .user-stats {
    width: 100%;
  }
}
@media screen and (max-width: 532px) {
  .user-chart {
    margin: 0 auto;
    width: 100%;
    overflow-x: auto;
  }
  .line-chart {
    width: 100%;
  }
  .charts {
    width: 100%;
  }

  .member-details {
    margin: 0 auto;
    width: 100%;
    overflow-x: auto;
  }
  .member-table {
    margin: 0 auto;

    width: 3000px;
  }
}
@media screen and (max-width: 390px) {
  .user-chart {
    margin: 0 auto;
    width: 100%;
  }
}

.member-details::-webkit-scrollbar {
  background-color: transparent;
  width: 8px; /* Adjust the width as desired */
  height: 4px;
}

.member-details::-webkit-scrollbar-track {
  background-color: transparent;
}

.member-details::-webkit-scrollbar-thumb {
  background-color: #588380;
  border-radius: 10px;
}

.member-details::-webkit-scrollbar-thumb:hover {
  background-color: #492b8e;
}

.member-details::-webkit-scrollbar-thumb:active {
  background-color: #3a2073;
}
@media (max-width: 500px) {
  .stats-page {
    padding: 1rem;
  }
  h1 {
    font-size: 1.5rem;
  }
  h2 {
    font-size: 1rem;
  }
  .stats-page > div {
    gap: 2rem;
    margin-bottom: 2rem;
  }
}
@media screen and (max-width: 800px) {
  .phase-toggle {
    flex-direction: column !important;
  }
}
@media screen and (max-width: 600px) {
  .home-page {
    width: 85%;
  }
  .registrationDetails {
    flex-direction: column !important;
  }
  .download-button {
    flex-direction: column !important;
  }
}
@media screen and (max-width: 700px) {
  .tracks-container {
    width: 70% !important;
  }
}
@media screen and (max-width: 450px) {
  .stats-page {
    width: 70%;
  }
  .phase-toggle-registrations {
    width: 70%;
  }
  .home-page {
    margin-left: 2rem;
  }
  .registrationH1 {
    font-size: 1.5rem !important;
  }
  .registrationH2 {
    font-size: 1rem !important;
  }
  .tracks-full {
    width: 104% !important;
  }
  .statistics {
    justify-content: center;
  }
}

.phase-toggle {
  display: flex;

  flex-direction: column;
  gap: 1rem;
  align-items: center;

  margin-bottom: 1rem;
}
.home-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  gap: 2rem;
  color: black;
  margin-bottom: 2rem;
}

.phase-toggle-registrations {
  display: flex;
  justify-content: space-between;
  border: 1px;
  padding: 2rem;
  box-shadow: rgba(0, 0, 0, 0.21) 0px 0px 81px 0px;
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  border-radius: 1rem;
}

.registrationH1 {
  color: #588380;
  font-size: 2.2rem;
  margin-top: 0.2rem;
  margin-left: 2rem;
}
.registrationH2 {
  display: flex;
  gap: 2rem;
  margin-top: 0.5rem;
  font-size: 2rem;
  margin-right: 2rem;
}

.registrationDetails {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
  color: brown;

  width: 100%;
}

.tracks-full {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  background-color: white;
  color: black;
  border-radius: 1rem;
  box-shadow: 0px 0px 81px 0px rgba(0, 0, 0, 0.21);
}
.tracks-container {
  /* display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: white;
  color: black;
  border-radius: 1rem;

  background: #f9f8f8;
  box-shadow: 0px 0px 81px 0px rgba(0, 0, 0, 0.21); */

  padding: 4rem;
  font-size: 1.5rem;
  width: 70%;
}

.download-button {
  display: flex;
  justify-content: space-between;

  flex-direction: row;
  border: 1px;
  padding: 2rem;
  margin-top: 2rem;
  box-shadow: rgba(0, 0, 0, 0.21) 0px 0px 81px 0px;
  border-radius: 1rem;
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;
}
.statistics {
  display: flex;
  flex-direction: row;
}
