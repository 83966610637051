/* This CSS file will remain common for all. */

@font-face {
  font-family: "NATS";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/NATS-Regular.otf");
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;600;800&family=Roboto+Slab:wght@200&display=swap');

  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

body {
  background-repeat: no-repeat;
  background-position: top;
  margin: 0px;
}
/* For WebKit-based browsers (Chrome, Safari, etc.) */
body::-webkit-scrollbar {
  display: none;
}

body::-webkit-scrollbar-thumb {
display: none;}

.error-in{
  font-family: 'Roboto Slab', serif;
}
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}
