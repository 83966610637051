@font-face {
  font-family: "NATS";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/NATS-Regular.otf");
}

.app-input-big,
.app-input,
.two-input input,
.input-country,
.input-dev,
.thirdPage input,
.thirdPage select,
textarea {
  /* ... Existing properties ... */
  /* border: 1px solid #ccc; */
  background-color: rgba(165, 164, 177, 0.24);
}
.loading-circle {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-top-color: #333;
  border-radius: 50%;
  animation: spin 1s infinite linear;
}
/* Update the color of .tags-input */
.tags-input {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 48px;
  width: 100%;
  padding: 0 8px;
  border: 1px solid rgb(28, 138, 247);
  /* background-color: rgba(165, 164, 177, 0.24) !important; */
  border-radius: 6px;
  background-color: #a5a4b1;
  transform: translateX(5%);
  /* opacity: 89.0%; */
}

/* Update the focus state of .tags-input */
.tags-input:focus-within {
  /* border: 1px solid #0052cc; */
}

/* Update the style of input field */
.tags-input input {
  flex: 1;
  border: none;
  height: 46px;
  font-size: 14px;
  padding: 4px 0 0 0;
}
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");
.proper-box {
  padding-bottom: 2rem !important;
  display: flex;
  justify-content: center;
  /* background-color: #3b1c7d; */
}
@media screen and (max-width: 567px) {
  .tags-input {
    width: calc(100vw - 32px);
  }
}
.point {
  cursor: not-allowed !important;
}
.idea-main {
  padding-bottom: 2rem !important;
  /* border : 2px dolid red:*/
  /* background-color: darkgoldenrod; */
  margin: 0px;
  /* margin-bottom: 2rem !important; */
}
.application-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 554px;
  margin: 0 auto;
  /* margin-bottom: 20000rem !important; */
  /* padding-bottom: 2rem !important; */
  border-radius: 16px;
  background-color: rgb(255, 255, 255);
  /* background-color: #0052cc; */
  width: 60%;
  margin-top: 75px;
  font-family: "NATS";
  letter-spacing: 2px;
  font-size: 20px;
  box-shadow: 7px 5px 81px 7px rgba(0, 0, 0, 0.25);
}
/* .application-box h1 {

  /* margin-bottom: -10px; */
/* } */
.application-box form {
  width: 700px;
  text-align: center;
}
.app-input-big {
  margin-top: 20px;
  width: 670px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: rgba(165, 164, 177, 0.24);
  border: 0px solid;
}
.app-input {
  margin-top: 20px;
  width: 300px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: rgba(165, 164, 177, 0.24);
  border: 0px solid;
}
.app-input::placeholder,
.app-input-big::placeholder {
  color: #9f9d9e;
  font-family: "Poppins", sans-serif;
  letter-spacing: 1px;
}
.app-input:focus,
.app-input-big::placeholder {
  outline: none;
}
.input-country {
  margin-top: 20px;
  padding: 15px;
  width: 330px;
  font-family: "Poppins", sans-serif;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: rgba(165, 164, 177, 0.24);
  border: 0px solid;
}
.input-dev {
  width: 40rem;
  padding: 15px;
  /* width: 330px; */
  font-family: "Poppins", sans-serif;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: rgba(165, 164, 177, 0.24);
  border: 0px solid;
}
.input-country-default {
  color: #9f9d9e;
  margin-top: 20px;
  padding: 15px;
  width: 330px;
  font-family: "Poppins", sans-serif;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: rgba(165, 164, 177, 0.24);
  border: 0px solid;
}
.input-dev-default {
  color: #9f9d9e;
  width: 40rem;
  padding: 15px;
  /* width: 330px; */
  font-family: "Poppins", sans-serif;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: rgba(165, 164, 177, 0.24);
  border: 0px solid;
}
.input-country-default option {
  color: black;
}
.input-country option {
  color: black;
}

.input-country::selection {
  color: black;
}
.input-dev-default option {
  color: black;
}
.input-dev option {
  color: black;
}

.input-dev::selection {
  color: black;
}
.two-input {
  display: flex;
  justify-content: space-between;
}
.mi-2 {
  margin-left: 15px;
}
.next-button {
  border-radius: 5px;
  background: #588380;
  padding: 10px 44px;
  color: #fff;
  cursor: pointer;
  border: 0px;
  font-size: 25px;
  margin-bottom: 30px;
  margin-right: 0.5rem;
}
.next-button:hover {
  background: #94b6b5;
  color: #fff;
}
.ideaidea {
  display: flex;
  justify-content: center;
}
.ideanext-button {
  border-radius: 5px;
  background: #588380;
  padding: 10px 44px;
  color: #111;
  cursor: pointer;
  border: 0px;
  font-size: 25px;
  margin-bottom: 30px;
  margin-right: 0.5rem;
  width: 200px;
  margin-top: 1.5rem;
}
.ideanext-button:hover {
  background: #94b6b5;
}
.wrapper {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  outline: none;
  /* height: 200%;Remove outline/border */
}
.apper {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  outline: none;
  /* height: 200%;Remove outline/border */
}

.check-terms {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  letter-spacing: 0;
}

.check-boxes {
  text-align: left;
  margin-bottom: 50px;
}
@media screen and (max-width: 1200px) {
  .application-box {
    width: 80%;
  }
}
@media screen and (max-width: 900px) {
  .application-box {
    width: 90%;
  }
}

@media screen and (max-width: 800px) {
  .application-box {
    width: 500px;
    padding-bottom: 5rem !important;
  }
  .two-input {
    text-align: center;
    align-items: center;
    flex-direction: column;
  }
  .application-box h1 {
    margin-bottom: -10px;
  }
  .app-input-big {
    width: 300px;
  }
  .thirdPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}
@media screen and (max-width: 800px) {
  .application-box form {
    width: 400px;
  }
}
@media screen and (max-width: 510px) {
  .application-box {
    width: 400px;
  }
}
@media screen and (max-width: 432px) {
  .app-input-big,
  .app-input {
    width: 200px;
    margin-top: 16px;
  }
  .input-country {
    width: 230px;
  }
  .input-country-default {
    width: 230px;
  }
  .input-dev {
    width: 230px;
  }
  .input-dev-default {
    width: 230px;
  }
  .application-box {
    margin-top: 50px;
    width: 90%;
    padding-bottom: 2rem !important;
  }
  .application-box h1 {
    font-size: 30px;
  }
  .application-box form {
    width: 300px;
    margin-top: -0.3px;
  }
  .application-box form button {
    font-size: 10px;
  }
  .check-boxes {
    font-family: "Poppins", sans-serif;
    margin-left: 30px;
    padding-right: 30px;
  }
}

/* Idea Submission */

.idea-form form textarea {
  width: 600px;
  padding: 15px;
  border-radius: 4px;
  background-color: rgba(165, 164, 177, 0.24);
  border: 0px solid;
}
.yash {
  width: 600px;
  padding: 15px;
  border-radius: 4px;
  background-color: rgba(165, 164, 177, 0.24) !important;
  border: 0px solid;
  /* border: 2px solid red !important; */
}
.idea-form form input {
  width: 600px;
  /* padding: 15px; */
  border-radius: 4px;
  background-color: rgba(165, 164, 177, 0);
  border: 0px solid;
}
.kush {
  width: 600px;
  /* padding: 15px; */
  border-radius: 4px;
  background-color: rgba(165, 164, 177, 0) !important;
  /* border: 1px solid; */
}

.idea-form form .select-option {
  /* width: 630px; */
  padding: 15px;
  border-radius: 4px;
  background-color: rgba(165, 164, 177, 0.24);
  border: 0px solid;
  color: #9f9d9e;
}
.idea-form form {
  border-radius: 30px;

  box-shadow: 7px 5px 81px 7px rgba(0, 0, 0, 0.25);
  font-family: "Poppins", sans-serif;
  margin: 0 auto;
  margin-top: 60px;
  /* margin-bottom: 80px; */
  background-color: #fff;
  padding: 50px 100px;
  width: 900px;
  margin-top: -5px;
  /* /* border : 2px dolid red:*/
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
}
.option-buttons {
  display: flex;
  width: 630px;
  justify-content: start;
}
.field-wrap {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  /* /* border : 2px dolid red:*/
  margin-bottom: 10px;
}
.field-wrap-idea-submission {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: center; */
  /* /* border : 2px dolid red:*/
  margin-bottom: 10px;
  width: 100%;
}
.field-wrap-idea-submission .hid {
  content: hide;
}
.technology-used {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  /* /* border : 2px dolid red:*/
  margin-bottom: 10px;
}

.field-wrap label {
  display: inline-block;
  word-wrap: break-word;
  width: 100%;
  contain: content;
}
.field-wrap-idea-submission label {
  display: inline-block;
  word-wrap: break-word;
  width: 100%;
  contain: content;
}
.technology-used label {
  display: inline-block;
  word-wrap: break-word;
  width: 100%;
  contain: content;
}
.field-wrap .button-container {
  display: flex;
  align-items: center;
}
.field-wrap-idea-submission .button-container {
  display: flex;
  align-items: center;
}
.technology-used .button-container {
  display: flex;
  align-items: center;
}

.field-wrap .button-container button {
  padding: 10px 60px;
  background-color: #c2dfed;
  border: 0px;
  border-radius: 6px;
  font-weight: bold;
}
.field-wrap-idea-submission .button-container button {
  padding: 10px 60px;
  background-color: #c2dfed;
  border: 0px;
  border-radius: 6px;
  font-weight: bold;
}
.technology-used .button-container button {
  padding: 10px 60px;
  background-color: #c2dfed;
  border: 0px;
  border-radius: 6px;
  font-weight: bold;
}

.required::after {
  content: "*";
  color: red;
}

label.required {
  margin-right: 10px;
  width: 100%;
}

.idea-form label {
  word-wrap: break-word;
  display: inline-block;
  width: 300px;
  margin-right: 50px;
  contain: content;
}
.idea-form form h1 {
  text-align: center;
  font-family: "Poppins", sans-serif;
  margin-bottom: 30px;
  /* margin-top: -20px; */
}
.idea-form form [type="submit"] {
  margin-top: 10px;
}
.idea-submit-button {
  margin: 0 auto;
  width: 200px;
  background-color: #588380;
  border: 0px;
  color: #111;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 2rem;
}
.idea-submit-button:hover {
  background-color: #021713;
  color: #fff;
}
.button-container {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.button-container button {
  display: inline-block;
  padding: 5px 10px;
  border: 1px solid #aaa;
  background-color: #fff;
  cursor: pointer;
}

.button-container button.active {
  background-color: #555;
  color: #fff;
}

.check-boxes {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 10px;
}

/* CSS for customizing checkboxes inside the check-boxes div */
/* input[type="checkbox"] {
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #fff;
  outline: none;
  cursor: pointer;
}

.check-boxes input[type="checkbox"]:checked:before {
  content: "\2713";
  display: block;
  text-align: center;
  color: #fff;
  background-color: #94b6b5;
  cursor: pointer;
} */

/* Checkbox label styles */
.check-boxes .check-terms {
  margin-left: 5px;
  cursor: pointer;
  margin-top: 0px;
}

.check-terms {
  margin-left: 10px;
  margin-right: 10px;
}
/* CSS to disable black border on input focus */
.app-input-big:focus,
.app-input:focus,
.two-input input:focus,
.input-country:focus,
.input-dev:focus,
.thirdPage input:focus,
.thirdPage select:focus,
textarea:focus {
  outline: none;
  border-color: initial;
  box-shadow: none;
}

/* CSS to set selected options as black */
.select-country .selected-option {
  color: black;
  color: black;
}
.select-dev .selected-option {
  color: black;
}
textarea {
  font-family: "Poppins", sans-serif;
}
.application-box {
  font-family: "Poppins", sans-serif;
  height: max-content;
}

.field-wrap-idea-submission input:focus {
  outline: none;
}
.field-wrap input:focus {
  outline: none;
}
.technology-used input:focus {
  outline: none;
}

.tags-input {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 48px;
  width: 500px;
  padding: 0 8px;
  border: 0px solid rgb(214, 216, 218);
  border-radius: 6px;
  /* &:focus-within {
		border: 0px solid #0052cc;
	}
	input {
		flex: 1;
		border: none;
		height: 46px;
		font-size: 14px;
		padding: 4px 0 0 0;
		&:focus {
			outline: transparent;
		} */
  /* } */
}

#tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 8px 0 0 0;
  width: 550px;
}

.tag {
  width: auto;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0 8px;
  font-size: 14px;
  list-style: none;
  border-radius: 6px;
  margin: 0 8px 8px 0;
  background: #588380;
}
.tag-title {
  margin-top: 3px;
}
.tag-close-icon {
  display: block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  font-size: 14px;
  margin-left: 8px;
  color: #0052cc;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
}

@media screen and (max-width: 567px) {
  .tags-input {
    width: calc(100vw - 32px);
  }
}

@media screen and (max-width: 1000px) {
  .idea-form form {
    width: 600px;
  }
  .tags-input {
    width: 370px;
  }
  .field-wrap .button-container button {
    padding: 10px 30px;
  }
  .field-wrap-idea-submission .button-container button {
    padding: 10px 30px;
    background-color: #c2dfed;
  }
  .technology-used .button-container button {
    padding: 10px 30px;
    background-color: #c2dfed;
  }
}
@media screen and (max-width: 800px) {
  .idea-form form {
    width: 600px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .field-wrap .button-container button {
    padding: 10px 20px;
  }
  .field-wrap-idea-submission .button-container button {
    padding: 10px 20px;
    background-color: #c2dfed;
  }
  .technology-used .button-container button {
    padding: 10px 20px;
    background-color: #c2dfed;
  }
  .check-boxes {
    font-family: "Poppins", sans-serif;
    margin-left: 30px;
    padding-right: 30px;
  }
}
@media screen and (max-width: 660px) {
  .idea-form form {
    width: 500px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .field-wrap .button-container button {
    padding: 10px 20px;
  }
  .field-wrap-idea-submission .button-container button {
    padding: 10px 20px;
    background-color: #c2dfed;
  }
  .technology-used .button-container button {
    padding: 10px 20px;
    background-color: #c2dfed;
  }
  .idea-form form input,
  .idea-form form textarea {
    width: 300px;
  }
  .idea-form form .select-option {
    width: 330px;
  }
  .tags-input {
    width: 230px;
  }
}

@media screen and (max-width: 545px) {
  .idea-form form {
    width: 450px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .field-wrap .button-container button {
    padding: 10px 20px;
    margin-bottom: 10px;
  }
  .technology-used .button-container button {
    padding: 10px 20px;
    margin-bottom: 10px;
  }
  .option-buttons {
    flex-direction: row;
    width: 100px;
  }
  .idea-form form input {
    width: 140px;
  }
  .idea-form form textarea {
    width: 180px;
  }
  .idea-form form .select-option {
    width: 210px;
  }
  .tags-input {
    width: 160px;
  }
}

@media screen and (max-width: 475px) {
  .idea-form form {
    width: 300px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .field-wrap .button-container button {
    padding: 10px 20px;
    margin-bottom: 10px;
  }
  .technology-used .button-container button {
    padding: 10px 20px;
    margin-bottom: 10px;
  }
  .option-buttons {
    flex-direction: row;
    width: 100px;
  }
  .idea-form form input {
    width: 80px;
  }
  .idea-form form textarea {
    width: 170px;
  }
  .idea-form form .select-option {
    width: 110px;
  }
  .tags-input {
    width: 103px;
  }
}

.error {
  display: flex;
  justify-content: center;
  margin-top: 20rem;
}
@media screen and (max-width: 500px) {
  .error {
    display: flex;
    justify-content: center;
    margin-top: 20rem;
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 1600px) {
  .idea-form form {
    margin-top: 85px;
  }
  .kush {
    width: 70%;
    /* padding: 15px; */
    border-radius: 4px;
    background-color: rgba(165, 164, 177, 0) !important;
    /* border: 1px solid; */
  }
  .tags-input {
    width: 90% !important;
    margin-left: 21px !important;
  }
}
@media screen and (max-width: 1110px) {
  .idea-form form {
    width: 724px;
  }
  .tags-input {
    width: 69% !important;
    margin-left: 100px !important;
  }
  #tags {
    width: 450px;
  }
  .kush {
    width: 88%;
    /* padding: 15px; */
    border-radius: 4px;
    background-color: rgba(165, 164, 177, 0) !important;
    /* border: 1px solid; */
  }
  /* .tags-input{
  width: 96% !important;
  margin-left: -19px !important;
} */
}

@media screen and (max-width: 950px) {
  .field-wrap {
    flex-direction: column;
  }
  /* .field-wrap-idea-submission{
  flex-direction: column;
 } */
  .technology-used {
    flex-direction: column;
  }
  .idea-form form {
    /* border : 2px dolid red:*/
    justify-content: center;
    /* align-items: center; */
    width: 724px !important;
  }
  .tags-input {
    width: 97% !important;
    margin-left: -32px !important;
  }
  #tags {
    width: 600px;
  }
}
@media screen and (max-width: 930px) {
  .back-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    background-color: #333;
    width: 50rem;
  }
  .idea-form form {
    /* border : 2px dolid red:*/
    justify-content: center;
    align-items: center;
    width: 650px !important;
  }
  .kush {
    width: 104%;
    /* padding: 15px; */
    border-radius: 4px;
    /* background-color: rgba(165, 164, 177,0) !important; */
    /* border: 1px solid; */
  }
  .tags-input {
    width: 98% !important;
    transform: translateX(4%);
    margin-left: -27px !important;
  }
  .technology-used {
    flex-direction: column;
    width: 94%;
    margin-right: 1rem;
  }
  .field-wrap-idea-submission {
    margin-left: 1rem !important;
  }
}
@media screen and (max-width: 860px) {
  .idea-form form {
    /* border : 2px dolid red:*/
    justify-content: center;
    align-items: center;
    width: 550px !important;
  }
  .field-wrap-idea-submission {
    width: 65%;
    margin-left: 1rem !important;
  }
  .technology-used {
    flex-direction: column;
    width: 111%;
    margin-right: 1rem;
  }
}
@media screen and (max-width: 800px) {
  .idea-form form {
    /* border : 2px dolid red:*/
    justify-content: center;
    align-items: center;
    width: 650px !important;
  }
  .technology-used {
    flex-direction: column;
    width: 95%;
    margin-right: 1rem;
  }
  .field-wrap-idea-submission {
    width: 65%;
    margin-left: 1rem !important;
  }
}
@media screen and (max-width: 720px) {
  .idea-form form {
    /* border : 2px dolid red:*/
    justify-content: center;
    align-items: center;
    width: 80% !important;
  }
  .kush {
    width: 68vw;
    /* padding: 15px; */
    border-radius: 4px;
    background-color: rgba(165, 164, 177, 0) !important;
    /* border: 1px solid; */
  }
  .idea-form form input {
    width: 90%;
  }
  .field-wrap {
    width: 90%;
  }
  .field-wrap-idea-submission {
    width: 65%;
    margin-left: -12rem !important;
  }
  .technology-used {
    width: 87%;
  }
  .idea-form form textarea {
    width: 90%;
  }
  .idea-form form .select-option {
    width: 96%;
  }
  .option-buttons {
    display: flex;
    flex-direction: row;
    width: 90%;
  }
  .tags-input {
    width: 98% !important;
    transform: translateX(4%);
    margin-left: -15px !important;
  }
  .input-dev {
    width: 97%;
  }
  .input-dev-default {
    width: 97%;
  }
}

@media screen and (max-width: 560px) {
  .idea-form form {
    /* border : 2px dolid red:*/
    justify-content: center;
    align-items: center;
    width: 80% !important;
  }
  .kush {
    width: 69vw;
    /* padding: 15px; */
    border-radius: 4px;
    background-color: rgba(165, 164, 177, 0) !important;
    /* border: 1px solid; */
  }
  .idea-form form input {
    width: 90%;
  }
  .field-wrap {
    width: 90%;
  }
  .field-wrap-idea-submission {
    width: 73%;
    margin-left: -7rem !important;
    gap: 2rem !important;
  }
  .technology-used {
    width: 86%;
  }
  .idea-form form textarea {
    width: 90%;
  }
  .idea-form form .select-option {
    width: 97%;
  }
  .option-buttons {
    display: flex;
    flex-direction: row;
    width: 90%;
  }

  .tags-input {
    width: 96% !important;
  }
}

@media screen and (max-width: 480px) {
  .idea-form form {
    /* border : 2px dolid red:*/
    justify-content: center;
    align-items: center;
    width: 83% !important;
  }

  .idea-form form input {
    width: 90%;
  }
  .technology-used {
    width: 86%;
    /* margin: 1rem; */
  }
  .kush {
    width: 70vw;
    border-radius: 4px;
    background-color: rgba(165, 164, 177, 0) !important;
    /* border: 1px solid; */
  }
  .idea-form form textarea {
    width: 90%;
  }
  .idea-form form .select-option {
    width: 99%;
  }
  .option-buttons {
    display: flex;
    flex-direction: row;
    width: 90%;
  }
  .tags-input {
    width: 100% !important;
    margin-left: -17px !important;
  }
  .field-wrap-idea-submission {
    width: 79%;
    margin-left: -4rem !important;
  }
  .input-dev {
    width: 98%;
  }
  .input-dev-default {
    width: 98%;
  }
}

@media screen and (max-width: 360px) {
  .idea-form form {
    /* border : 2px dolid red:*/
    justify-content: center;
    align-items: center;
    width: 90% !important;
  }

  .idea-form form input {
    width: 80%;
  }
  .technology-used {
    width: 80%;
    /* margin: 1rem; */
  }
  .kush {
    width: 65w;
    border-radius: 4px;
    background-color: rgba(165, 164, 177, 0) !important;
    /* border: 1px solid; */
  }
  .idea-form form textarea {
    width: 80%;
  }
  .idea-form form .select-option {
    width: 88%;
  }
  .option-buttons {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .tags-input {
    width: 100% !important;
    margin-left: -17px !important;
  }
  .field-wrap-idea-submission {
    width: 70%;
    margin-left: -4rem !important;
  }
  .input-dev {
    width: 88%;
  }
  .input-dev-default {
    width: 88%;
  }
}
/* ArrowBackButton.css */

.arrow-back-container {
  position: absolute;
  top: 20px; /* Adjust the positioning as needed */
  left: 20px; /* Adjust the positioning as needed */
}

.arrow-back-button {
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333; /* Change the color as needed */
  transition: color 0.3s;
  font-size: 3rem;
  font-weight: 600;
}

.arrow-back-button:hover {
  color: #ff0000; /* Change the color for hover effect */
}

textarea {
  resize: none;
}

@-moz-document url-prefix() {
  /* .input-dev-default
  {
    width: 53rem;
  }
 .input-dev
  {
    width: 53rem;
  } */
  @media screen and (max-width: 1110px) {
    /* .kush{

      width: 89%;


    margin-left: 1.9rem;
  } */
  }
  @media screen and (max-width: 720px) {
    /* .input-dev{
    width: 97%;
  }
  .input-dev-default{
    width: 97%;
  } */
  }
  @media screen and (max-width: 950px) {
    /* .kush{

      width: 89%;


    margin-left: 0px;
  } */
  }
  @media screen and (max-width: 930px) {
    /* .tags-input{
    width: 113% !important;
  } */
  }
}
