@font-face {
  font-family: "NATS Regular";
  src: url("../fonts/NATS-Regular.otf");
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

.user-app-box {
  display: flex;
  justify-content: center;
  margin-top: 80px;
  padding: 8px;
}
.user-container {
  width: 569.5px;
  padding-top: 20px;
  padding-bottom: 80px;
  margin-top: -30px;
  text-align: center;
  border-radius: 10px;
  background: #fff;
  box-shadow: 7px 5px 81px 7px rgba(0, 0, 0, 0.25);
}
.user-container h1 {
  color: #000;
  text-align: center;
  font-size: 38px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  line-height: normal;
  font-weight: 350;
}

.user-container form input[type="text"]::placeholder {
  color: #9f9d9e;
  font-family: "Poppins", sans-serif;
  letter-spacing: 1px;
}
.user-container form input[type="text"]:focus {
  outline: none;
}
.user-container form input[type="text"] {
  width: 300px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: rgba(165, 164, 177, 0.24);
  border: 0px solid;
}
.user-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -21px;
}
.user-container form button {
  width: 320px;
  padding: 10px;
  background-color: #588380;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
}
.prompt_c {
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.8px;
  margin-right: 7px;
}
.prompt_c a {
  color: #588380;
  text-decoration: none;
}
@media screen and (max-width: 415px) {
  .user-container {
    width: 369.5px;
    margin-top: 15px;
  }
  .user-container h1 {
    color: #000;
    text-align: center;
    font-size: 35px;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .user-container form input[type="text"] {
    width: 200px;
  }
  .user-container form button {
    width: 220px;
  }
  .user-app-box {
    margin-top: 55px;
  }
}
.user-container form button:hover {
  background-color: #2f4c4a;
}
.user-welcome button {
  width: 320px;
  padding: 10px;
  background-color: rgba(165, 164, 177, 0.24);
  border: 2px #588380 solid;
  filter: drop-shadow(-1px 6px 4px rgba(0, 0, 0, 0.25));
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
  color: #000;
  letter-spacing: 1px;
  font-size: 20px;
  cursor: pointer;
}
.user-welcome button:hover {
  background-color: #588380;
  color: #fff;
}
.user-welcome p {
  color: #787777;
  font-family: "Poppins", sans-serif;
}

@media screen and (max-width: 375px) {
  .user-welcome button {
    width: 280px;
  }
}
.error-msg {
  color: red;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  margin-top: 5px;
}
.wrapper {
  position: relative;
  width: 100%;
  /* background-repeat: no-repeat; */
  background-size: cover;
  background-position: center 0px;
  outline: none; /* Remove outline/border */
  height: 100vh;
}
.apper {
  background-size: cover;
  background-repeat: none;
  background-attachment: fixed;
  background-position: center;
  outline: none; /* Remove outline/border */
  /* height: 100vh; */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: height 0.3s ease;
}
