input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 40px #d7d7df inset !important;
}
/* #d7d7df */
.error-in {
  font-family: "Roboto", sans-serif;
}
@font-face {
  font-family: "NATS Regular";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/NATS-Regular.otf");
}
.loading-circle {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-top-color: #333;
  border-radius: 50%;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

.timer a {
  color: rgb(56, 56, 184);
  text-decoration: underline;
  cursor: pointer;
}
.onboard-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 350px;
  margin: 0 auto;
  border-radius: 16px;
  background-color: rgba(255, 255, 255, 0.8);
  margin-top: 60px;
}

.onboard-box h1 {
  font-family: "NATS Regular";
  font-weight: 1200;
  font-size: 46px;
  letter-spacing: 1px;
  margin: 0;
  margin-top: 40px;
  margin-bottom: 25px;
}

.onboard-box form {
  margin-top: -31px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.onboard-box-input,
.onboard-box-input2,
.onboard-box button,
.onboard-box a,
.password-toggle-icon {
  margin: 5px 0;
}

.onboard-box-input {
  width: 250px;
  border: 2px solid #588380;
  border-radius: 122px;
  height: 40px;
  background-color: #d7d7df;
  padding-left: 25px;
  /* border: none !important; */
}
.onboard-box-input2 {
  /* width: 250px; */
  /* border: 2px solid #588380; */
  /* border-radius: 122px; */
  height: 30px;
  background-color: #d7d7df;
  /* padding-left: 25px; */
  border: none !important;
  margin-right: 2rem;
}

.onboard-box-input:focus {
  outline-color: #588380;
}
.onboard-box-input2:focus {
  outline-color: #588380;
}

.onboard-box-input::placeholder {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #4c3f3fb5;
}
.onboard-box-input2::placeholder {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #4c3f3fb5;
  border: none;
}

.onboard-box p {
  font-weight: 600;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  margin-bottom: 50px;
}
.onboard-box button {
  width: -webkit-fill-available; /* For Safari and other WebKit-based browsers */
  width: 100%; /* Fallback for other browsers */
  border-radius: 10px;
}
.onboard-box p a {
  text-decoration: none;
  color: #588380;
}

.onboard-box form #forgot {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 125px;
  text-decoration: none;
  color: #588380;
  font-weight: 600;
  font-size: 14px;
  background-color: transparent;
  font-family: "Poppins", sans-serif;
  border: none;
  cursor: pointer;
}

.onboard-box form #forgot:hover {
  color: #332c72;
}
input:focus {
  outline: none !important;
}
button:active {
  outline: none;
  border: none;
}
.onboard-box form #log {
  width: 100px;
  padding-top: 10px;
  font-family: "NATS Regular";
  letter-spacing: 1px;
  font-weight: 500;
  border: 2px solid #588380;
  background-color: #588380;
  color: white;
  font-size: 16px;
  border-radius: 10px;
  line-height: normal;
  cursor: pointer;
}

.ReactModal__Overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5) !important;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 40000px;
}

.ReactModal__Content {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  margin: auto;
  max-width: 500px;
  margin-top: 11rem !important;
}

.ReactModal__Content {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  margin: auto;
  max-width: 500px;
  width: 90%;
  background-color: #fff;
  padding: 30px !important;
  outline: none;
}

#reset-title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-left: 10px;
  margin-right: 10px;
  font-weight: 100px;
}

#reset-p {
  font-family: "Poppins", sans-serif;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: -5px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  font-size: 20px;
}

#reset-form {
  text-align: center;
}

#email-res {
  width: 91.5%;
  border: 2px solid #588380;
  border-radius: 122px;
  height: 40px;
  background-color: #d7d7df;
  padding-left: 20px;
  color: black;
  font-size: 20px;
}
#email-res:focus {
  outline-color: #4e0479;
}
#reset-form button {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  cursor: pointer;
  width: 96%;
  border: 0px solid #4e0479;
  height: 45px;
  margin-top: 20px;
  color: #fff;
  background-color: #588380;
  border-radius: 122px;
}
.verify {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  cursor: pointer;
  width: 96%;
  border: 0px solid #4e0479;
  height: 45px;
  margin-top: 20px;
  color: #fff;
  background-color: #588380;
  border-radius: 122px;
}
#reset-form button:hover {
  background-color: #2f4c4a;
}
.timer {
  position: absolute;
  /* bottom: 10px; */
  right: 30px;
  /* background-color: #D9D9D9; */
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
}
.resend {
  border-radius: 5px;
  background: linear-gradient(180deg, #5614e3 0%, rgba(85, 38, 162, 0.83) 100%);
  /* padding: 10px 44px; */
  padding: 0.5rem 1.2rem;
  color: #fff;
  cursor: pointer;
  border: 0px;
  /* font-size: 25px; */
  /* margin-bottom: 30px;
  margin-right: 0.5rem; */
}
#reset-form button:disabled {
  background-color: #c2dfed;
  color: #dddddd;
  cursor: default;
}

.modal-top {
  display: flex;
  justify-content: space-between;
}

#reset-button {
  background-color: transparent;
  border: 0px;
  cursor: pointer;
}

#reset-button:hover {
  color: #588380;
}

#pass-res {
  font-size: 30px;
}

#change-p {
  border: 2px solid #588380;
  background-color: #fff;
}

#reset {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  cursor: pointer;
  width: 95%;
  border: 2px solid #588380;
  background-color: #588380;
  height: 45px;
  margin-top: 20px;
  border-radius: 122px;
  margin-bottom: 40px;
}

#reset:hover {
  background-color: #3c3486;
}

.onboard-box-input[type="email"]:focus {
  border-color: #588380;
}

/* Media Queries */
@media screen and (max-width: 510px) {
  .onboard-box {
    margin-top: 120px;
    width: 350px;
  }
}

@media screen and (max-width: 436px) {
  .onboard-box {
    width: 350px;
  }
}

@media screen and (max-width: 650px) {
  .ReactModal__Content {
    width: 450px;
  }
}

@media screen and (max-width: 560px) {
  #reset-p,
  #reset-form button {
    font-size: 16px;
  }
  #email-res {
    font-size: 16px;
  }
  #reset-title {
    font-size: 25px;
  }
  .ReactModal__Content {
    width: 350px;
  }
}

@media screen and (max-width: 460px) {
  .ReactModal__Content {
    width: 300px;
  }
}

@media screen and (max-width: 440px) {
  .ReactModal__Content {
    width: 280px;
    height: 330px;
  }
}

@media screen and (max-width: 390px) {
  .ReactModal__Content {
    width: 250px;
  }
}

@media screen and (max-width: 350px) {
  .onboard-box {
    width: 300px;
  }
  #pass-res {
    font-size: 25px;
  }
  .ReactModal__Content {
    margin-left: -16px;
    width: 230px;
  }
}
.wrapper {
  position: relative;
  width: 100%;
  /* background-repeat: no-repeat; */
  background-size: cover;
  background-position: center;
  outline: none; /* Remove outline/border */
  height: 100vh;
}

.password-error-message {
  font-family: "Roboto", sans-serif !important;
  color: red;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 0 !important;
  padding-bottom: 0;
  /* font-size: small !important; */
}
.success-message {
  color: rgb(45, 144, 15);
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 0 !important;
  padding-bottom: 0;
}

.password-toggle-icon {
  background-color: #e5dddd;
  /* width: 20px !important; */
  /* height: 2rem;
  padding-top: 20rem; */
  /* position: absolute; */
  /* margin-left: 500rem !important; */
  display: contents;
  left: 20rem !important;

  margin-top: 200rem !important;
}

.password-input-container {
  width: 250px;
  border: 2px solid #588380;
  border-radius: 122px;
  height: 40px;
  background-color: #d7d7df;
  padding-left: 25px;
  margin: 5px 0;
}
.create {
  font-size: small;
}
