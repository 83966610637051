.nav-img {
  width: 190px;
  padding-top: 30px;
  padding-left: 20px;
  width: 150px;
  padding-top: 20px;
}

.navbar {
  margin-bottom: -20px;
  padding-right: 10px;
  padding-left: 10px;
}
.navbar-dynamic {
  display: flex;
  justify-content: space-between;
  padding-left: 120px;
  padding-right: 120px;
  margin-bottom: 20px;
}
@media screen and (max-width: 1000px) {
  .navL {
    margin-top: 50px;
  }
  .navLL {
    margin-top: 50px;
  }
}

@media screen and (max-width: 600px) {
  .nav-img {
    padding-left: 30px;
    width: 260px;
  }
}

@media screen and (max-width: 450px) {
  .nav-img {
    width: 150px;
    height: 62px;
  }
}
@media screen and (max-width: 350px) {
  .nav-img {
    padding-left: 1px;
    width: 100px;
  }
}
@media screen and (max-width: 820px) {
  .navLL {
    visibility: visible !important;
  }
}

.navL {
  display: flex;
  justify-content: center;
  /* margin-left: 2rem !important; */
  align-items: center;
  /* cursor: pointer; */
}
.navLL {
  display: flex;
  visibility: hidden;
  justify-content: center;
  /* margin-left: 2rem !important; */
  align-items: center;
  /* cursor: pointer; */
}

.navL .i {
  /* font-size: 30px; */
  /* margin-right: 5px; */
  /* margin-top: -12px;
    color: gainsboro;*/
  font-size: 30px;
  margin-right: 5px;
  margin-top: 21px;
  color: gainsboro;
  transform: scale(1.1);
}
.navLL .i {
  /* font-size: 30px; */
  /* margin-right: 5px; */
  /* margin-top: -12px;
    color: gainsboro;*/
  font-size: 30px;
  margin-right: 5px;
  margin-top: 21px;
  color: gainsboro;
  transform: scale(1.1);
}

.navL p {
  font-size: 13px;
  /* margin-right: 35px; */
  margin-top: 0.5px;
  padding: 1px 7px;
  border-radius: 10px;
  color: #c4dada;
  font-family: "Poppins", sans-serif;
  border: 3px solid rgba(167, 145, 229, 1);
}
.navLL p {
  font-size: 13px;
  /* margin-right: 35px; */
  margin-top: 0.5px;
  padding: 1px 7px;
  border-radius: 10px;
  color: #c4dada;
  font-family: "Poppins", sans-serif;
  border: 3px solid rgba(167, 145, 229, 1);
}
.logout {
  cursor: pointer;
}
.user-icon {
  cursor: pointer;
}
.user-info {
  position: absolute;
  background-color: #fff;
  padding: 10px;
  margin-left: 2rem;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  font-family: "Poppins", sans-serif;
  justify-content: center !important;
  flex-direction: column;
  font-size: 10px;
}
.i {
  margin-left: 100px !important;
}
.user-info p {
  margin-bottom: 5px;
  border: 0px;
  text-align: left;
  color: #588380;
}

.user-info a {
  font-size: 13px;
  color: #333;
  text-decoration: none;
}

.user-info a:hover {
  text-decoration: underline;
}
@media screen and (max-width: 1000px) {
  .navbar-dynamic {
    padding-left: 10px;
    padding-right: 10px;
  }
  .user-icon {
    cursor: pointer;
    margin-top: -2.4rem;
  }
}
@media screen and (max-width: 520px) {
  .nav-img {
    width: 150px;
  }
  /* .navL p{
    display: none;
  } */
  .user-info {
    right: 31px;
  }
}
