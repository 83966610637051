.bottom-bar{
    margin: 0 auto;
    border-radius: 32px;
    background: #FFF;
    width: 500px;
    display: flex;
    justify-content: space-evenly;
    box-shadow: 0px 0px 18px 20px rgba(0, 0, 0, 0.20);
    backdrop-filter: blur(40.5px);
    height: 50px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: none;
    font-family: "Poppins", sans-serif;
    position: fixed;
    bottom: 0;
}
.bottom-bar .link{
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 10px;
    color: rgba(149, 166, 217, 1);
    text-decoration: none;
}
.link-active{
    color: rgba(98, 62, 201, 1);
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 10px;
    text-decoration: none;
}

@media screen and (max-width: 820px){
    .bottom-bar{
        display: flex;
    }
}
@media screen and (max-width: 505px){
    .bottom-bar{
        width: 100%;
    }
    .bottomBar{
        padding-bottom: 2rem;
    }
    .bottom-bar .link{
        font-size: 11px;
    }
    .link-active{
        font-size: 11px;
    }
}