@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

.team-details {
  font-family: "Poppins", sans-serif;
  background-color: #fff;
  margin: 0 auto;
  transition: 0.5s;
  border-radius: 10px;
  box-shadow: 0px 0px 18px 20px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(40.5px);
  margin-top: 50px;
  width: 1300px;
  text-align: center;
  /* overflow-x: auto;  */
  padding-bottom: 3rem;
}
.team-table {
  overflow-x: auto;
}

.search-bar {
  background-color: #e1e1e1;
  border-radius: 100px;
  width: 300px;
  /* margin: 0 auto; */
  padding: 10px;
  display: inline;
}
.search-bar i {
  color: #959090;
}
.search-bar input {
  width: 300px;
  margin-left: 20px;
  background-color: transparent;
  border: 0px solid;
}
.search-bar input:focus {
  outline: none;
}
.team-details h1 {
  color: #588380;
}
table {
  width: 1200px;
  margin: 0 auto;
  overflow: auto;

  border-collapse: collapse;
  margin-top: 30px;
}

th,
td {
  padding: 10px;

  /* text-align: center; */
}

th {
  color: #a3aed0;
}

td.status {
  font-weight: bold;
}

.status-approved {
  color: green;
}

.status-not-approved {
  color: red;
}

.status-in-review {
  color: blue;
}

.value {
  color: #2b3674;
  text-align: center;
}

@media screen and (max-width: 1460px) {
  table {
    width: 1300px;
  }
  .team-details {
    overflow-x: auto;

    width: 1100px;
  }
}

@media screen and (max-width: 1270px) {
  table {
    width: 1200px;
  }

  .team-details {
    width: 800px;
  }
}
@media screen and (max-width: 970px) {
  table {
    width: 1200px;
  }
  .team-details {
    width: 650px;
  }
}
@media screen and (max-width: 820px) {
  table {
    width: 1500px;
  }
  .team-details {
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media screen and (max-width: 410px) {
  .search-bar input {
    width: 200px;
  }
}
.previous-btn,
.next-btn {
  background-color: transparent;
  border: 0px;
  color: #588380;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  margin-bottom: 20px;
  cursor: pointer;
}
.previous-btn:hover,
.next-btn:hover {
  color: #432b86;
}
.previous-btn:disabled,
.next-btn:disabled {
  color: #633ec96f;
  cursor: default;
}

.team-table {
  /* Other styles */
  /* overflow-y: auto; */
}

.team-table::-webkit-scrollbar {
  width: 3px; /* Adjust the width as desired */
  height: 4px;
  background-color: transparent;
}

.team-table::-webkit-scrollbar-track {
  background-color: transparent;
}

.team-table::-webkit-scrollbar-thumb {
  background-color: #490cf1;
  border-radius: 6px;
}

.team-table::-webkit-scrollbar-thumb:hover {
  background-color: #492b8e;
}

.team-table::-webkit-scrollbar-thumb:active {
  background-color: #3a2073;
}

.details {
  background-color: #f6f3f3;
  border-radius: 0.2rem;
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
  color: #3a2073;
  font-weight: 800;
  margin-top: 1rem;
}

.table-scroll-container {
  overflow-x: auto; /* Enable horizontal scrolling */
  max-width: 100%; /* Ensure the container doesn't exceed the screen width */
  width: 1200px;
  margin-top: -30px;
  overflow-x: auto;
  background-color: #f6f3f3;
  border-radius: 0 0 0.7rem 0.7rem;
  text-align: none !important;
  /* Customize the scrollbar */
  scrollbar-width: thin;
  scrollbar-color: #588380 #f6f3f3;
}

.table-container {
  /* Your existing table styles */
}

.team-details {
  margin-top: 1rem;
}
.name {
  background-color: #f6f3f3;
  border-radius: 0 0 0.7rem 0.7rem;
  text-align: none !important;
  width: 1200px;
  margin-top: 0px;
  overflow-x: auto;

  /* Customize the scrollbar */
  scrollbar-width: thin;
  scrollbar-color: #588380 #f6f3f3; /* Change the colors as needed */
}

/* WebKit (Chrome, Safari) scrollbar customization */
.table-scroll-container::-webkit-scrollbar {
  width: 8px;
  height: 0.5rem; /* Adjust the width of the scrollbar */
}

.table-scroll-container::-webkit-scrollbar-thumb {
  background-color: #588380; /* Thumb color */
  border-radius: 6px; /* Radius of the thumb */
}

.table-scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: #492b8e; /* Thumb color on hover */
}

.table-scroll-container::-webkit-scrollbar-track {
  background-color: #f6f3f3; /* Track color */
  border-radius: 6px; /* Radius of the track */
}

tbody .see-details i {
  /* display: flex !important;
  flex-direction: row !important; */
  /* background-color: #492b8e; */
  margin-top: 0.8rem;
}

/* Add this to your existing team-details.css or create a new CSS file */
/* Common Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: white;
  margin: auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  max-width: 100%;
  width: 117%;
  height: max-content;
  position: relative;
  padding-bottom: 5rem;
}

.modal-close-inside {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .modal {
    width: 95%;
  }

  .modal-header {
    flex-direction: column;
    align-items: center;
    padding: 1rem;
  }
}

/* Modal Content Styles */
.details-table-container {
  overflow-x: auto;
  transform: scale(0.9);
  width: 101%;
}

.tdth {
  padding: 10px;
  white-space: wrap;
  text-align: start;
  width: 400px;
  border: 1px solid;
}

.trtrt {
  width: 12px !important;
}

/* Add your other styles */

/* Status Buttons */
.status-buttons {
  display: flex;
  justify-content: flex-start;
  margin-top: 1rem;
}

.approve-button,
.reject-button {
  padding: 0.5rem 1rem;
  margin-left: 0.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition:
    background-color 0.3s,
    color 0.3s;
}

.approve-button {
  background-color: #2ecc71;
  color: white;
}

.reject-button {
  background-color: #e74c3c;
  color: white;
}

.approve-button:hover,
.reject-button:hover {
  background-color: darken(#2ecc71, 10%);
}

/* Add your other styles above */

/* Search Bar */
.search-bar {
  height: 1.25rem; /* Adjust the value as needed */
}

.search-bar-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 2rem;
  margin-right: 1rem;
  gap: 2rem;
}

/* Media Query for Mobile */
@media screen and (max-width: 700px) {
  .search-bar-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .search-bar {
    width: 100%; /* Use full width for mobile */
  }
}

/* Additional Styles */

/* Editable Span */
.editable-span {
  display: inline-block;
  padding: 1px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: text;
  min-width: 40px; /* Adjust as needed */
  text-align: center;
}

.editable-span:hover {
  border-color: #9373ea; /* Highlight border color on hover */
}

/* Download Button */
.download-button2 {
  margin-top: 10px;
}

.download-button2 button {
  padding: 10px 20px;
  background-color: #588380;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.download-button2 button:hover {
  background-color: #94b6b5;
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .modal {
    width: 95%;
  }

  .modal-header {
    flex-direction: column;
    align-items: center;
    padding: 1rem;
  }
}

/* Screen size 1460px and larger */
/* Mobile Styles */
@media screen and (max-width: 768px) {
  .modal {
    width: 48rem; /* Adjust the value as needed */
  }

  .modal-header {
    flex-direction: column;
    align-items: center;
    padding: 1rem;
  }
}

/* Screen size 1460px and larger */
@media screen and (max-width: 1460px) {
  .modal {
    position: static;
  }
  .trtrt {
    display: flex;
    flex-direction: column;
  }
  .details-table-container {
    margin-top: -7rem; /* Adjust the value as needed */
    width: 70rem; /* Adjust the value as needed */
  }
  .tdth {
    width: 68rem; /* Adjust the value as needed */
  }
}

/* Screen size 1260px to 1460px */
@media screen and (max-width: 1270px) {
  .tdth {
    width: 47rem; /* Adjust the value as needed */
  }
}

/* Screen size 970px to 1260px */
@media screen and (max-width: 970px) {
  .tdth {
    width: 38rem; /* Adjust the value as needed */
  }
  .details-table-container {
    margin-top: -4rem;
    width: 40rem; /* Adjust the value as needed */
  }
}

/* Screen size 820px to 970px */
@media screen and (max-width: 820px) {
  .tdth {
    width: 38rem; /* Adjust the value as needed */
  }
  .details-table-container {
    margin-top: -3rem; /* Adjust the value as needed */
  }
}

/* Screen size 800px to 820px */
@media screen and (max-width: 820px) {
  .tdth {
    width: 37.5rem; /* Adjust the value as needed */
  }
  .details-table-container {
    margin-top: -6.4rem;
    width: 96rem; /* Adjust the value as needed */
  }
}

/* Screen size 350px to 800px */
@media screen and (max-width: 800px) {
  .tdth {
    width: 46rem; /* Adjust the value as needed */
  }
  .details-table-container {
    margin-top: -7rem; /* Adjust the value as needed */
    width: 52.5rem; /* Adjust the value as needed */
  }
}

/* Screen size less than 350px */
@media screen and (max-width: 350px) {
  .modal {
    width: 48rem; /* Adjust the value as needed */
  }
  .tdth {
    width: 48rem; /* Adjust the value as needed */
  }
  .details-table-container {
    margin-top: -7rem; /* Adjust the value as needed */
    width: 53rem; /* Adjust the value as needed */
  }
}
@media screen and (max-width: 550px) {
}
@media screen and (max-width: 750px) {
  .details-table-container {
    margin-top: -7rem; /* Adjust the value as needed */
    width: 43rem; /* Adjust the value as needed */
  }
  .tdth {
    width: 35rem;
  }
}
@media screen and (max-width: 700px) {
  .details-table-container {
    margin-top: -7rem; /* Adjust the value as needed */
    width: 96rem; /* Adjust the value as needed */
  }
  .tdth {
    width: 30rem !important;
  }
}
@media screen and (max-width: 670px) {
  .tdth {
    width: 30rem;
  }
}
@media screen and (max-width: 620px) {
  .details-table-container {
    width: 63rem;
  }
}
@media screen and (max-width: 560px) {
  .tdth {
    width: 26rem !important;
  }
}
@media screen and (max-width: 500px) {
  .details-table-container {
    width: 30rem;
  }
}
@media screen and (max-width: 470px) {
  .details-table-container {
    width: 61rem;
    margin-top: -11rem;
  }
  .tdth {
    width: 20rem !important;
  }
}
@media screen and (max-width: 420px) {
  .details-table-container {
    width: 70rem;
  }
  .tdth {
    width: 15rem !important;
  }
}
@media screen and (max-width: 370px) {
  .details-table-container {
    width: 47rem;
  }
  .tdth {
    width: 15rem !important;
  }
}
@media screen and (max-width: 780px) {
  .tdth {
    width: 41rem;
  }
}

.details-table {
  overflow-x: hidden !important;
}
.modal {
  overflow-x: hidden !important;
}
.details-table-container {
  overflow-x: hidden !important;
}
.modal-overlay {
  overflow-x: hidden !important;
  background-color: #2ecc71;
}

.tdth {
  overflow-x: hidden !important;
}

.tbody-details {
  overflow: hidden !important;
}
